const VaultDataItemTypeConstant = {
    TEXT: "text",
    DATE: "date",
    TAG: "tag",
    LTAG: "ltag",
    EMAIL: "email",
    ORGANIZATION_ID: "organizationId",
    FILESTATUS: "filestatus"
};

{
    angular.module('adminApp')
        .constant('VaultDataItemTypeConstant', VaultDataItemTypeConstant);
}
